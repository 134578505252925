<template>
    <div v-loading.fullscreen.lock="loading">
        <entertain-search-bar-component
            add_auth="新增招待申请"
            :AllClient="AllClient"
            @search="get_entertain_index"
            @addTeam="addTeam"
        ></entertain-search-bar-component>
        <common-table-component
            details_auth="招待申请详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_entertain"
            table_height="549px"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_entertain_index"
        ></common-page-component>
        <entertain-edit-component
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :entertain_details_data="entertain_details_data"
            :enterprise_department="enterprise_department"
            :edit_auth="edit_auth"
            :del_auth="del_auth"
            :add_file_auth="add_file_auth"
            :to_approval_auth="to_approval_auth"
            :sel_approval_auth="sel_approval_auth"
            :ApprovalAuth="ApprovalAuth"
            :withdraw_auth="withdraw_auth"
            :AllClient="AllClient"
            print_auth="打印招待申请"
            :budget_count="budget_count"
            @show_edit="show_edit"
            @addTeam="addTeam"
            @search="get_entertain_index"
            @exitEntertainDialog="dialogExit"
            @frefresh="frefresh"
            @details_row="details_entertain"
        ></entertain-edit-component>
    </div>
</template>

<script>
import { enterprise_department_request} from '@/network/enterprise.js'
import { entertain_index_request,entertain_details_request} from '@/network/finance/entertain.js'
import { thousandBitSeparator,fomatFloat} from '@/assets/js/common.js'
import { entertain_client_list_request} from '@/network/list.js'
import { budget_count_request } from '@/network/finance/BudgetUse.js'

import EntertainSearchBarComponent from '@/components/finance/entertain/EntertainSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import EntertainEditComponent from '@/components/finance/entertain/EntertainEditComponent'

export default {
    name:'',
    data(){
        return {
            fid: 0,
            edit_type: 0,
            loading: false,
            fdialogFormVisible: false,
            cond: {
                name: '',
                state: '',
                use_state: '',
                client_id:'',
                enterprise_id: '',
                department_id: '',
                staff_id: '',
                title_content: '',
                page: 1,
                limit: 10,
            },
            ftotal: 0,
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'apply_date',
                    label: '申请日期',
                    minWidth: '100px',
                },
                {
                    prop: 'name',
                    label: '招待单号',
                    minWidth: '120px'
                },
                {
                    prop: 'state',
                    label: '审批状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '待提交'
                            }
                        } else if(d===1) {
                            return {
                                type: '',
                                filter_data: '审批中'
                            }
                        } else if(d===2) {
                            return {
                                type: 'success',
                                filter_data: '已通过'
                            }
                        } else if(d===3) {
                            return {
                                type: 'danger',
                                filter_data: '已驳回'
                            }
                        }
                    }
                },
                {
                    prop: 'use_state',
                    label: '使用情况',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '未使用'
                            }
                        } else if(d===1) {
                            return {
                                type: 'success',
                                filter_data: '已使用'
                            }
                        }
                    }
                },
                {
                    prop: 'title',
                    label: '标题',
                    minWidth: '300px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'money',
                    label: '预计花费(元)',
                    minWidth: '120px',
                    editdata(row) {
                        return thousandBitSeparator(fomatFloat(row['money']))
                    }
                },
                {
                    prop: 'current_examine_staff_name',
                    label: '当前审批人',
                    minWidth: '100px',
                },
                {
                    prop: 'staff_name',
                    label: '申请人',
                    minWidth: '100px',
                },
                {
                    prop: 'department_name',
                    label: '申请部门',
                    minWidth: '120px'
                },
                {
                    prop: 'enterprise_name',
                    label: '申请企业',
                    minWidth: '260px'
                }
            ],
            entertain_details_data: {},            
            enterprise_department: [],
            AllClient: [],
            edit_auth: false,
            del_auth: false,
            //补充附件
            add_file_auth: false,
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //撤回审批
            withdraw_auth: false,
            budget_count: {
                all_quota: 0,
                use_quota: 0,
                per_quota: 0,
                entertain_quota: 0,
                travel_quota: 0,
                other_quota: 0
            }
        }
    },
    computed:{},
    methods:{
        get_entertain_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.state = param.state ?? this.cond.state
            this.cond.use_state = param.use_state ?? this.cond.use_state
            this.cond.client_id = param.client_id ?? this.cond.client_id
            this.cond.enterprise_id = param.enterprise_id ?? this.cond.enterprise_id
            this.cond.department_id = param.department_id ?? this.cond.department_id
            this.cond.staff_id = param.staff_id ?? this.cond.staff_id            
            this.cond.title_content = param.title_content ?? this.cond.title_content
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            entertain_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_entertain(id) {
            this.fid = id
            this.fdialogFormVisible = true
            entertain_details_request(id)
                .then((s) => {                    
                    if (s.status === 0) {
                        this.entertain_details_data = s.result                        
                        this.entertain_details_data.department_id = [s.result.enterprise_id,s.result.department_id]
                        //权限判断
                        this.is_auth(s.result)
                        //获取预算
                        const department_id = this.entertain_details_data.department_id[1]
                        const year = this.entertain_details_data.apply_date ? this.entertain_details_data.apply_date.substr(0,4) : 0
                        this.get_budget_count(department_id ,year)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
            this.budget_count ={
                all_quota: 0,
                use_quota: 0,
                per_quota: 0,
                entertain_quota: 0,
                travel_quota: 0,
                other_quota: 0
            }
        },
        show_edit() {
            this.edit_type = 2
            this.budget_count ={
                all_quota: 0,
                use_quota: 0,
                per_quota: 0,
                entertain_quota: 0,
                travel_quota: 0,
                other_quota: 0
            }
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
            this.budget_count ={
                all_quota: 0,
                use_quota: 0,
                per_quota: 0,
                entertain_quota: 0,
                travel_quota: 0,
                other_quota: 0
            }
        },
        get_enterprise_department() {
            this.loading = true
            enterprise_department_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.enterprise_department = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        frefresh() {
            this.details_entertain(this.fid)
            this.get_entertain_index()
        },
        is_auth(res) {
            this.edit_auth = false
            this.del_auth = false
            this.add_file_auth = false
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.withdraw_auth = false
            
            if(res.state === 0) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交招待申请')
                    this.edit_auth = this.$_has('修改招待申请')
                    this.del_auth = this.$_has('删除招待申请')
                }
            } else if(res.state === 1) {
                 //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.withdraw_auth = this.$_has('撤回招待申请')
                    this.add_file_auth = this.$_has('补充招待申请附件')
                }
                //判断当前审批人是否为自己
                if(res.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批招待申请')
                }
                this.sel_approval_auth = this.$_has('查看招待申请审批记录')
            } else if(res.state === 2) {
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.add_file_auth = this.$_has('补充招待申请附件')
                }
                this.sel_approval_auth = this.$_has('查看招待申请审批记录')
            } else if(res.state === 3) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交招待申请')
                    this.edit_auth = this.$_has('修改招待申请')
                    this.del_auth = this.$_has('删除招待申请')
                }
                this.sel_approval_auth = this.$_has('查看招待申请审批记录')
            }
        },
        get_all_entertain_client() {
            entertain_client_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllClient = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        get_budget_count(department_id,year) {
            budget_count_request({ department_id:department_id, year:year })
                .then((s) => {
                    this.budget_count = s.result
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_enterprise_department()              
        this.get_entertain_index()
        this.get_all_entertain_client()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        EntertainSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        EntertainEditComponent
    },
    watch:{}
}
</script>

<style lang='less'></style>